import * as React from "react"
import {Helmet} from "react-helmet"

const BlogIndex = () => {
  return (
    <Helmet>
      <meta http-equiv="refresh" content="0;URL='https://generate.design/'" />
    </Helmet>
  )
}

export default BlogIndex
